import React, { useState, useRef, useEffect } from "react"
import { debounce } from 'lodash'
import { navigate } from 'gatsby'

import Checkbox from 'components/checkbox'
import DropDown from 'components/dropdown'
import SearchFormMeta from 'components/searchFormMeta'

import { cloneDeep } from 'lodash'

import { getParsedQuery } from 'utils'

import { 
    getLocalitySuggestions,
    ucFirst,
    capitaliseString
} from 'utils'

export default ({
    type,
    setInputFocus = () => {},
    handleSubmit,
    buyRentActiveTab,
    setBuyRentActiveTab,
    hideTabs,
    isFiltersActive,
    handleFiltersClick
}) => {
    const tabs = ['buy','rent']

    const [localActiveTab, setActiveTab] = useState(tabs[0])

    const [searchInput, setSearchInput] = useState('')
    const [selectedRegion, setSelectedRegion] = useState(null)
    const [includeSurrounds, setIncludeSurrounds] = useState(false)
    const [localitySuggestions, setLocalitySuggestions] = useState([])
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)
    const [selectedMeta, setSelectedMeta] = useState({})

    const selectedRegionRef = useRef(selectedRegion)
    const includeSurroundsRef = useRef(includeSurrounds)
    const selectedMetaRef = useRef(selectedMeta)

    selectedRegionRef.current = selectedRegion
    includeSurroundsRef.current = includeSurrounds
    selectedMetaRef.current = selectedMeta

    let activeTab = null 

    if(buyRentActiveTab) {
        activeTab = buyRentActiveTab
    } else {
        activeTab = localActiveTab
    }

    const [activeDropDown, setActiveDropDown] = useState(null)

    useEffect(() => {
        const searchDataQueryParam = getParsedQuery()

        if(searchDataQueryParam){
            setSelectedRegion(searchDataQueryParam.region)
            setSearchInput(searchDataQueryParam.region ? searchDataQueryParam.region.locality : '')
            setIncludeSurrounds(searchDataQueryParam.includeSurrounds)

            _handleSubmit({
                region: searchDataQueryParam.region,
                includeSurrounds: searchDataQueryParam.includeSurrounds
            })
        }
    }, [])
	
    const _handleChangeSearchTerm = term => {
        if(term){
            const response = getLocalitySuggestions(term)

            if(!response || !response.length){
                setSuggestionsVisible(false)
            }else{
                setLocalitySuggestions(response)

                setTimeout(() => {
                    setSuggestionsVisible(true)
                },10)
            }
        }else{
            setSuggestionsVisible(false)
        }
	}
	
	const debouncedHandleChangeSearchTerm = useRef(debounce(_handleChangeSearchTerm, 300))

    const handleChangeSearchTerm = term => {
        debouncedHandleChangeSearchTerm.current(term)

        if(setInputFocus && term){
            setInputFocus(true)
        }

        if(!term){
            setLocalitySuggestions([])
        }

        setSelectedRegion(null)
		
		setSearchInput(term)
	}

    const searchContainerName = `search-box-container ${ activeTab }`
    const buttonsClassName = `buttons ${ activeTab }`

    const handleRegionClick = locality => {
        setSelectedRegion(locality)
        setSearchInput(locality.locality)
        
        setSuggestionsVisible(false)
    }

    const _handleSubmit = newState => {
        const values = newState || {
            region: selectedRegion,
            includeSurrounds,
            ...selectedMeta
        }

        handleFiltersClick()

        if(values.region){
            if(handleSubmit){
                handleSubmit(values)
            }else{
                navigate(`/buy-rent?searchData=${JSON.stringify(values)}`)   
            }
        }
    }

    const handleChangeMeta = (key, value) => {
        const _selectedMeta = cloneDeep(selectedMeta)

        _selectedMeta[key] = value

        setSelectedMeta(_selectedMeta)

        if(key !== 'priceRange'){
            setTimeout(() => {
                setActiveDropDown(null)
            },400)
        }
    }

    const formatSuggestions = () => {
        const _suggestions = []

        if(localitySuggestions && localitySuggestions.length){
            localitySuggestions.forEach(suggestion => {
                const _suggestion = {
                    ...suggestion
                }

                _suggestion.locality = capitaliseString(suggestion.locality)

                _suggestions.push(_suggestion)
            })
        }

        _suggestions.sort((a, b) => {
            return a.locality.length - b.locality.length
        })

        return _suggestions
    }

    const suggestions = formatSuggestions()

    const handleInputFocus = () => {
        setInputFocus(true)

        if(suggestions && suggestions.length){
            setSuggestionsVisible(true)
        }
    }

    const handleInputBlur = () => {
        if(!searchInput){
            setInputFocus(false)
        }

        setSuggestionsVisible(false)
    }

    const handleChangeTab = tab => {
        const callback = buyRentActiveTab ? setBuyRentActiveTab : setActiveTab

        callback(tab)

        if(handleSubmit){
            setTimeout(() => {
                handleSubmit()
            },5)
        }
    }

    const selectValues = [
        {
            type: 'numbers',
            label: 'Beds',
            key: 'beds'
        },
        {
            type: 'numbers',
            label: 'Baths',
            key: 'baths'
        },
        {
            type: 'list',
            label: 'Property Type',
            key: 'propertyType'
        },
        {
            type: 'range',
            label: activeTab === 'rent' ? 'Price pw' : 'Price',
            key: 'priceRange'
        }
    ]

    return (
        <div 
            className={`SearchForm mobile ${ isFiltersActive ? 'mobile-active' : '' } ${ type } ${ activeTab } ${ hideTabs ? 'plain' : '' }`}
            onClick={() => setActiveDropDown(null)}
        >
            <div className="form">
                <div className="filters-heading">
                    <h3>Filters</h3>
                    <button onClick={handleFiltersClick}>Close</button>
                </div>
                { !hideTabs ?
                    <div className="tabs-container">
                        <div className="buttons-container">
                            <ul className={buttonsClassName}>
                                { tabs.map(tab => (
                                    <li 
                                        className={activeTab == tab ? 'active' : null} 
                                        onClick={() => handleChangeTab(tab)}
                                        key={tab}
                                    >
                                        <span>
                                            { ucFirst(tab) }
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                : null }
                <div className={searchContainerName}>
                    <div className="container">
                        <div className="wrapper">
                            <form
                                onSubmit={e => {
                                    e.preventDefault()

                                    if(selectedRegion) {
                                        _handleSubmit()
                                    }
                                }}
                                className={type}
                            >
                                <div className="row fields">
                                    <div className="input-container">
                                        <input
                                            className="keyword"
                                            type="text"
                                            placeholder={type === 'complex' ? 'Suburb' : `Where are you looking to ${ activeTab }?`}
                                            value={searchInput}
                                            onChange={e => handleChangeSearchTerm(e.target.value)}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                        />
                                        <DropDown
                                            open={suggestionsVisible}
                                            options={suggestions}
                                            labelKey={'locality'}
                                            onSelect={handleRegionClick}
                                        />
                                    </div>
                                    <div className="row additional-fields">
                                        <Checkbox
                                            label={'Include surrounding suburbs?'}
                                            isChecked={includeSurrounds}
                                            handleChange={() => setIncludeSurrounds(!includeSurrounds)}
                                        />
                                    </div>
                                    { type == 'complex' &&
                                        <>
                                            { selectValues.map(selectValue => {
                                                const { key } = selectValue

                                                const isOpen = true

                                                return (
                                                    <SearchFormMeta
                                                        {...selectValue}
                                                        open={isOpen}
                                                        toggle={() => setActiveDropDown(isOpen ? null : key)}
                                                        setVisible={isVisible => {
                                                            if(isVisible){
                                                                setActiveDropDown(key)
                                                            }else if(isOpen){
                                                                setActiveDropDown(null)
                                                            }
                                                        }}
                                                        handleChange={value => handleChangeMeta(key, value)}
                                                        value={selectedMeta[key] || null}
                                                        activeTab={activeTab}
                                                    />
                                                )
                                            })}
                                        </>
                                    }
                                    <input 
                                        type="submit" 
                                        value={`Find property`}
                                        disabled={!selectedRegion}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}