import React, { useState, useEffect } from "react"

import FadeCollapse from 'components/fadeCollapse'

export default ({
    open,
    options,
    labelKey,
    onSelect,
    content,
    type
}) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(open){
            setIsOpen(true)
        }else{
            setTimeout(() => {
                setIsOpen(false)
            },30)
        }
    }, [open])

    return (
        <FadeCollapse
            open={isOpen}
            className={type || ''}
        >
            <ul 
                className="suggestions"
            >
                { content || options.map((option, index) => {
                    const label = option[labelKey]

                    if(!label){
                        return null
                    }

                    return (
                        <li key={index} onClick={() => onSelect(option)}>
                            { label }
                        </li>
                    )
                })}
            </ul>
        </FadeCollapse>
    )
}