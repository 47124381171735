import React, { useRef } from "react"
import DropDown from 'components/dropdown'
import SearchFormMetaNumbers from 'components/searchFormMetaNumbers'
import SearchFormMetaRange from 'components/searchFormMetaRange'

export default ({
    type,
    label,
    value,
    open,
    toggle,
    handleChange,
    setVisible,
    activeTab,
}) => {
    let content = null
    let options = []

    let _label = label

    const openRef = useRef(open)
    openRef.current = open

    switch(type){
        case 'numbers':
            if(value){
                const singularLabel = label.toLowerCase().slice(0, -1)

                _label = `${ value } ${ singularLabel }${ value !== 1 ? 's' : ''}`
            }

            content = (
                <SearchFormMetaNumbers
                    value={value}
                    handleChange={handleChange}
                />
            )
            break
        case 'range':
            let minValue = 300000
            let maxValue = 3000000
            let step = 50000

            if (activeTab === 'rent') {
                minValue = 50
                maxValue = 5000
                step = 50
            }

            const emptyLabel = 'Any'
        
            const getCopy = () => {
                const getDollarValue = _value => {
                    if (activeTab === 'rent') {
                        return `$${ _value }`
                    } else {
                        return `$${ _value < 1000000 ? _value/1000+'k' : _value/1000000+'M' }`
                    }
                }
        
                const startValue = (value && value.start !== minValue) ? value.start : null
                const endValue = (value && value.end !== maxValue) ? value.end : null
        
                if(startValue || endValue){
                    if(startValue && endValue){
                        return `${ getDollarValue(startValue) } - ${ getDollarValue(endValue) }`
                    }else if(startValue){
                        return `Above ${ getDollarValue(startValue) }`
                    }else{
                        return `Below ${ getDollarValue(endValue) }`
                    }
                }else{
                    return emptyLabel
                }
            }
        
            const copy = getCopy()

            if(copy !== emptyLabel){
                _label = copy
            }

            content = (
                <SearchFormMetaRange
                    handleChange={handleChange}
                    value={value}
                    minValue={minValue}
                    maxValue={maxValue}
                    step={step}
                    copy={copy}
                />
            )
            break
        case 'list':
            if(value){
                _label = value.label
            }

            options = [
                {
                    label: 'Apartment',
                },{
                    label: 'House'
                },{
                    label: 'Townhouse'
                },{
                    label: 'Land'
                }
            ]

            break
    }

    return (
        <div 
            className={'search-form-select'}
            onClick={e => e.stopPropagation()}
        >
            <button 
                onClick={e => {
                    const _open = openRef.current

                    e.preventDefault()

                    if(_open){
                        setVisible(false)
                    }else{
                        setVisible(true)
                    }
                }}
                onFocus={() => {
                    setTimeout(() => {
                        setVisible(true)
                    },100)
                }}
                onBlur={() => {
                    if(type !== 'range'){
                        setVisible(false)
                    }
                }}
            >
                { _label }
                <i className={'fe fe-chevron-down'} />
            </button>
            <DropDown
                open={open}
                options={options}
                labelKey={'label'}
                onSelect={handleChange}
                content={content}
                type={type}
            />
        </div>
    )
}
