import React from "react"
import { times } from 'lodash'

export default ({
    handleChange,
    value
}) => {
    return (
        <>
            <li className={!value ? 'active' : ''}>
                <button tabIndex={-1} onClick={() => handleChange(null)}>
                    Any
                </button>
            </li>
            { times(5, i => {
                return  (
                    <li key={i} className={value === (i + 1) ? 'active' : ''}>
                        <button tabIndex={-1} onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleChange(i + 1)
                        }}>
                            { i + 1 }{ i === 4 ? '+' : ''}
                        </button>
                    </li>
                )
            }) }
        </>
    )
}