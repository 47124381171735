import React, { useRef, useState } from 'react'

import PropertyGridItem from 'components/propertyGridItem'
import markerImage from 'images/marker.png'

export default ({
    handleClick: _handleClick,
    active,
    property
}) => {
    const markerRef = useRef(null)

    const handleClick = e => {
        e.stopPropagation()

        setTimeout(() => {
            _handleClick()
        },10)
    }

    return (
        <div 
            className="marker"
            onClick={handleClick}
            ref={ref => markerRef.current = ref}
        >
            <img src={markerImage} />
            <div className={`info-window left`}>
                <div className={active ? 'visible' : '' }>
                    <PropertyGridItem
                        property={property}
                    />
                </div>
            </div>
        </div>
    )
}
