import React from "react"

export default ({
    isChecked,
    label,
    handleChange
}) => {
    return (
        <label className={`checkbox ${ isChecked ? 'checked' : ''}`}>
            <div className="input-container">
                <input 
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={handleChange}
                />
                <i />
            </div>
            <span>
                { label }
            </span>
        </label>
    )
}