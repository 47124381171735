import React, { useRef } from "react"
import { RangeSlider } from 'reactrangeslider'

export default ({
    handleChange,
    value,
    minValue,
    maxValue,
    copy,
    step
}) => {
    const _value = value || { start: minValue, end: maxValue }
    const blockUpdateRef = useRef(false)

    const _handleChange = newValue => {
        if(!blockUpdateRef.current){
            handleChange(newValue)
        }
    }

    const handleMouseUp = () => {
        blockUpdateRef.current = true

        setTimeout(() => {
            blockUpdateRef.current = false
        },200)
    }

    return (
        <div
            onMouseUp={handleMouseUp}
        >
            <span>
                { copy }
            </span>
            <RangeSlider
                onChange={_handleChange}
                min={minValue}
                max={maxValue}
                value={_value}
                step={step}
            />
        </div>
    )
}