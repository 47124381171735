import React, { useEffect, useRef, useState } from "react"
import { useIsMobile } from 'utils'

const { jarallax } = typeof window !== "undefined" ? require("jarallax") : () => null

export default ({ 
    children, 
    className = '', 
    backgroundUrl = null,
    mobileBackgroundUrl = null,
    secondaryBackgroundUrl = null,
    secondaryBackgroundActive = false,
    darken,
    blockParallax,
    speed = -0.6,
    homeHero,
}) => {
    let hasInitialisedJarallax = false

	const isMobile = useIsMobile()

    const ref = useRef(null)
    const [isVisible, setIsVisible] = useState(isMobile || blockParallax)

    useEffect(() => {
        if(backgroundUrl && ref && ref.current && !hasInitialisedJarallax){
            hasInitialisedJarallax = true

            if(!isMobile && !blockParallax && !homeHero) {
                jarallax(ref.current, {
                    speed,
                    onInit: () => {
                        setIsVisible(true)
                    }
                })
            }

        }
    }, [backgroundUrl])

    return (
        <section className={`jarallax ${ className } ${ darken ? 'darken' : ''} ${ secondaryBackgroundActive ? 'secondary-active' : '' } ${ !isVisible && !homeHero ? 'hidden' : '' }`}
            style={{
                backgroundImage: (!blockParallax && backgroundUrl) ? `url(${ backgroundUrl })` : null
            }}
            ref={ref}
        >
            { secondaryBackgroundUrl &&
                <img className={'bg-secondary'} src={secondaryBackgroundUrl} />
            }
            { blockParallax &&
                <>
                    <img className={'bg'} src={backgroundUrl} />
                    { mobileBackgroundUrl ? <img className={'mobilebg'} src={mobileBackgroundUrl} /> : '' }
                </>
            }
            <div className="inner">
                { children }
            </div>
        </section>
    )
}