import React, { useState, useEffect, useContext, useRef } from "react"
import SearchForm from 'components/searchForm'
import SearchFormMobile from 'components/searchFormMobile'
import { MainContext } from "context/context"

import ParallaxSection from 'components/parallaxSection'

export default ({
    heading, 
    headingColour, 
    copy, 
    image, 
    backgroundImage,
    mobileBackgroundImage,
    secondaryBackgroundImage,
    searchForm, 
    searchFormType,
    darkenBackground = true,
    parallaxOverflowVisible,
    handleSearchSubmit,
    buyRentActiveTab,
    setBuyRentActiveTab,
    hideTabs,
    homeHero,
}) => {
    const [isFormFocused, setIsFormFocused] = useState(false)
    const [hasMounted, setHasMounted] = useState(false)
    const [_buyRentActiveTab, _setBuyRentActiveTab] = useState('buy') // for home page
	const {
        addInvertedHeaderSection,
        activateMobileFilter,
    } = useContext(MainContext)
    const elementRef = useRef(null)

    const [isFiltersActive, setIsFiltersActive] = useState(false)

    const handleFiltersClick = () => {
        if (isFiltersActive) {
            setIsFiltersActive(false)
            activateMobileFilter(false)
        } else {

            console.log('aaaaa')
            if(typeof window !== 'undefined'){
                window.scrollTo({top: 0, behavior: 'smooth'})
            }

            setIsFiltersActive(true)
            activateMobileFilter(true)
        }
    }

    useEffect(() => {
        setIsFiltersActive(false)
        
        setTimeout(() => {
            setHasMounted(true)
        },500)

        return () => {
            activateMobileFilter(false)
        }
    }, []) 
    
    useEffect(() => {
        if(elementRef && elementRef.current && !parallaxOverflowVisible){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const _setIsFormFocused = focused => {
        if(parallaxOverflowVisible){
            return 
        }
        
        if(isFormFocused !== focused){
            setIsFormFocused(focused)
        }
    }

    const handleSetBuyRentActiveTab = tab => {
        if(setBuyRentActiveTab){
            setBuyRentActiveTab(tab)
        }else{
            _setBuyRentActiveTab(tab)
        }
    }

    const handleScroll = () => {
        const element = document.getElementById('hero-content')
        const y = element.offsetHeight - 50
        window.scrollTo({top: y, behavior: 'smooth'})
    }

    const backgroundImageUrl = backgroundImage ? (backgroundImage.mediaItemUrl || backgroundImage.sourceUrl) : ''
    const mobileBackgroundUrl = mobileBackgroundImage ? mobileBackgroundImage.mediaItemUrl : ''
    const imageUrl = image ? image.mediaItemUrl : ''

    let className = ''

    if(isFormFocused){
        className += ' form-focused'
    }

    if(parallaxOverflowVisible){
        className += ' visible-parallax-overflow'
    }

    if(hasMounted){
        className += ' mounted'
    }

    if(hideTabs){
        className += ' no-tabs'
    }
    
    return (
        <>
            <ParallaxSection
                backgroundUrl={backgroundImageUrl}
                mobileBackgroundUrl={mobileBackgroundUrl}
                secondaryBackgroundUrl={secondaryBackgroundImage ? secondaryBackgroundImage.mediaItemUrl : ''}
                secondaryBackgroundActive={_buyRentActiveTab === 'rent'}
                darken={darkenBackground && !parallaxOverflowVisible}
                className={className}
                blockParallax={parallaxOverflowVisible}
                homeHero={homeHero}
            >
                <div id='hero-content' 
                    className={`content${ imageUrl ? ' has-image' : ''}${ searchForm ? '' : ' no-search'}`} 
                    ref={ref => elementRef.current = ref}
                >
                    <div 
                        className="text" 
                        style={{
                            color: headingColour
                        }}
                    >
                        { imageUrl &&
                            <div className="image">
                                <h3>
                                    WELCOME home
                                </h3>
                                <img src={imageUrl} />
                            </div>
                        }
                        <h1 dangerouslySetInnerHTML={{ __html: heading}} />
                        { copy &&
                            <p dangerouslySetInnerHTML={{ __html: copy }} />
                        }
                    </div>
                    { searchForm ? '' : <i className="hero-down fe fe-chevron-down" onClick={() => handleScroll()} /> }
                    { searchForm &&
                        <SearchForm 
                            type={searchFormType}
                            setInputFocus={_setIsFormFocused}
                            handleSubmit={handleSearchSubmit}
                            buyRentActiveTab={buyRentActiveTab || _buyRentActiveTab}
                            setBuyRentActiveTab={handleSetBuyRentActiveTab}
                            hideTabs={hideTabs}
                            handleFiltersClick={handleFiltersClick}
                        />
                    }
                    { searchForm ? <i className="hero-down search fe fe-chevron-down" onClick={() => handleScroll()} /> : '' }
                </div>
            </ParallaxSection>
            { searchForm &&
                <SearchFormMobile 
                    type={searchFormType}
                    setInputFocus={_setIsFormFocused}
                    handleSubmit={handleSearchSubmit}
                    buyRentActiveTab={buyRentActiveTab || _buyRentActiveTab}
                    setBuyRentActiveTab={handleSetBuyRentActiveTab}
                    hideTabs={hideTabs}
                    handleFiltersClick={handleFiltersClick}
                    isFiltersActive={isFiltersActive}
                    handleFiltersClick={handleFiltersClick}
                />
            }
        </>
    )
}
