import React, { useState, useEffect, useRef } from "react"

import Hero from 'components/hero'
import { doPropertySearch } from 'utils'
import Button from 'components/button'

import PropertyGridItem from 'components/propertyGridItem'

import Map from 'components/map'

export default ({ 
    heroHeading,
	heroBackground,
	type = 'default'
 }) => {
	const toggleTypes = [
		{
			icon: 'list',
			label: 'List',
			type: 'list'
		}, {
			icon: 'map-pin',
			label: 'Map',
			type: 'map'
		}
	]

	let tabs = [] 

	switch(type){
		case 'leased':
			tabs = ['past_rent']
			break
		case 'sold':
			tabs = ['past_buy']
			break
		default:
			tabs = ['buy','rent']
			break
	}

	const visibleResultsIncrementer = 12

	const [isLoading, setLoading] = useState(false)
	const [isLoadingWithDelay, setLoadingWithDelay] = useState(false)
	const [propertyData, setPropertyData] = useState({})
	const [isPristine, setIsPristine] = useState(true)
	const [activeView, setActiveView] = useState(toggleTypes[0].type)
	const [activeTab, setActiveTab] = useState(tabs[0])
	const [visibleResults, setVisibleResults] = useState(visibleResultsIncrementer)
	const [gridHeight, setGridHeight] = useState(0)
	const gridHeightRef = useRef(gridHeight)
	gridHeightRef.current = gridHeight

	const gridRef = useRef(null)

	const activeTabRef = useRef(activeTab)
	activeTabRef.current = activeTab

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const searchDataURL = urlParams.get('searchData')

		if (!searchDataURL) {
			_doPropertySearch({})
		}
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setLoadingWithDelay(isLoading)
		}, 10)
	}, [isLoading])

	const _doPropertySearch = (inputs = {}) => {
		setLoading(true)
		setIsPristine(false)

		const _activeTab = activeTabRef.current
		
		let _region = inputs.region ? JSON.stringify(inputs.region) : null
		let _beds = inputs.beds || null
		let _baths = inputs.baths || null
		let _propertyType = inputs.propertyType ? JSON.stringify(inputs.propertyType) : null
		let _priceRange = inputs.priceRange ? JSON.stringify(inputs.priceRange) : null
		let _includeSurrounds = inputs.includeSurrounds

		doPropertySearch(_activeTab, _region, _beds, _baths, _propertyType, _priceRange, _includeSurrounds).then(({ data }) => {
			setLoading(false)
			setPropertyData(data)
		}).catch(error => {
			setLoading(false)
		})
	}

	const handleSubmit = values => {
		_doPropertySearch(values)
	}

	const handleShowMore = _ => {
		setVisibleResults(visibleResults + visibleResultsIncrementer)
	}

	const searchResults = propertyData && propertyData.results
	
	let _searchResults = searchResults || []

	if (_searchResults) {
		_searchResults.sort(function(a,b){
			return (b.created_at > a.created_at) ? 1 : (b.created_at < a.created_at) ? -1 : 0;
		})
	}
	
	const _searchResultsVisible = _searchResults.length > visibleResults ? _searchResults.slice(0, visibleResults) : _searchResults
	const hasMoreResults = _searchResults.length > visibleResults

	return (
		<>
			<div>
				<section className="Hero">
					<Hero
						heading={heroHeading}
						backgroundImage={heroBackground}
						darkenBackground={false}
						parallaxOverflowVisible
						searchForm={true}
						searchFormType={'complex'}
						hideTabs={type !== 'default'}
						handleSearchSubmit={handleSubmit}
						buyRentActiveTab={activeTab}
						setBuyRentActiveTab={setActiveTab}
					/>
				</section>
				<section className="property-grid">
					<div className="container">
						{ type === 'sold' || type === 'leased' || type === 'default' ?
							<>
								<div className="total-results">
									<p>
										{isLoading ? 
											`Loading results...` 
										: 
										propertyData.total_results ?
											type === 'default' &&
												`There are ${propertyData.total_results} properties for ${ activeTab === 'buy' ? 'sale' : 'rent' } within your search results.`
										:
										isPristine ?
											`Please select a suburb to get started.`
										:
											`There aren't any results for this search.`
										}
									</p>
								</div>
							</>
						: null }
						{ type === 'default' ?
							(propertyData && propertyData.total_results) ?
								<div className="toggle-results-type">
									<ul>
										{toggleTypes.map(type => (
											<li key={type.icon}>
												<button
													onClick={() => setActiveView(type.type)}
													className={activeView === type.type ? 'active' : ''}
												>
													<div className="circle">
														<i className={`fe fe-${type.icon}`} />
													</div>
													<span>
														{type.label}
													</span>
												</button>
											</li>
										))}
									</ul>
								</div>
							: null
						: null }
						<div className={`map${ activeView === 'map' ? ' active' : '' }`}>
							<Map
								results={searchResults}
							/>
						</div>
						<div 
							className={`grid-container ${ activeView === 'list' ? 'active' : '' }`}
						>
							<div 
								className={'grid-transition-container'}
								style={{
									height: gridHeightRef.current ? gridHeightRef.current+'px' : undefined
								}}
							>
								<ul 
									ref={ref => gridRef.current = ref}
									className={`grid ${ isLoadingWithDelay ? 'loading' : '' }`}
								>
									{_searchResultsVisible && _searchResultsVisible.map((property, index) => {
										return (
											<PropertyGridItem
												key={property.id}
												property={property}
												transitionDelay={index/40}
											/>
										)
									})}
								</ul>
							</div>
							{ hasMoreResults &&
								<div className="button-container">
									<Button 
										onClick={handleShowMore}
										label={'Show more'}
										icon={'plus'}
									/>
								</div>
							}
						 </div>
					</div>
				</section>
			</div>
		</>
	)
}
