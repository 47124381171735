import React, { useState, useRef } from 'react'
import { useIsMobile } from 'utils'

import GoogleMapReact from 'google-map-react'
import mapStyle from 'utils/mapStyle'
import MapMarker from './mapMarker'

const googleMapsApiKey = 'AIzaSyDavGNTrnCg4JiQLr2pr38TKdfTO9-okjo'

export default ({
	results
}) => {
	const [activeMarkerIndex, setActiveMarkerIndex] = useState(null)

	const isMobile = useIsMobile()
	const mapRef = useRef()
	
	function _setActiveMarkerIndex(index, latitude, longitude) {
		mapRef.current.panTo({ lat: (latitude - 0.04 ), lng: isMobile ? ( longitude + 0.06 ) : ( longitude + 0.01 ) })
		setActiveMarkerIndex(index)
	}

	return (
        <div 
            className="google-map"
            onClick={_ => {
                setActiveMarkerIndex(null)
            }}
        >
			<GoogleMapReact
				bootstrapURLKeys={{ key: googleMapsApiKey }}
				defaultCenter={{
					lat: parseFloat(-37.7690417), 
					lng: parseFloat(145.0396898)
				}}
				defaultZoom={12}
				yesIWantToUseGoogleMapApiInternals
				options={{
					zoomControl: true,
					fullscreenControl: false,
					styles: mapStyle
				}}
				onGoogleApiLoaded={({ map }) => {
					mapRef.current = map
				}}
			>

				{results && results.map((property, index) => {
					return (
						<MapMarker
                            key={ index }
                            handleClick={_ => _setActiveMarkerIndex(index, property.address.latitude, property.address.longitude)}
                            active={activeMarkerIndex === index}
							lat={ property.address.latitude }
                            lng={ property.address.longitude }
							property={property}
						/>
					)
				})}

			</GoogleMapReact>
		</div>
	);
}
